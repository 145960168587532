import $ from "jquery";

$(function() {
    const $blocks = $(".bl-swiper");
    if($blocks.length == 0) {
        return;
    }

    $blocks.each(function() {
        const $bl = $(this);
        const swiper = new Swiper($bl.find(".swiper").get(0), {
            loop:true,
            
            effect: "fade",
            speed: 2000,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },

            navigation: {
                nextEl: $bl.find(".swiper-button-next").get(0),
                prevEl: $bl.find(".swiper-button-prev").get(0),
            }
        });
    });

});