import $ from "jquery";

$(function() {
    const $blocks = $(".bl-blogoverview");
    if($blocks.length == 0) {
        return;
    }

    $blocks.each(function() {
        const $bl = $(this);
        const $select = $bl.find(".yearselect");
        if($select.length == 0) {
            return;
        }

        function filterParent(parent) {
            $bl.find(".item").hide();
            $bl.find(".item[data-parent='"+parent+"']").show();
            console.log(parent);
        }

        $select.on("change", function() {
            filterParent($select.val());
        });
        filterParent($select.val());
    });

});