import $ from "jquery";
import "jquery.scrollto";

$(function() {
    if($(".bl-iconlist").length == 0) {
        return;
    }
    $(".bl-iconlist").find(".item").each(function() {
        const $item = $(this);
        const gotoanchor = $item.data("gotoanchor");
        if(!gotoanchor) {
            return;
        }

        $item.addClass("hasanchor");

        $item.on("click", function() {
            const $scrollTarget = $("[data-anchor='"+gotoanchor+"']");
            if($scrollTarget.length > 0) {
                const menuiconbarHeight = $("#menuiconbar").outerHeight();
                $(window).scrollTo($scrollTarget, 500, {
                    offset: -menuiconbarHeight
                });
                /*$scrollTarget.get(0).scrollIntoView({
                    behavior: "smooth"
                });*/
            }
        });
    });
});