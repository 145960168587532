import ScrollReveal from "scrollreveal";

//scrollreveal
ScrollReveal().reveal(".fade-in-bottom", {
  origin: "bottom",
  distance: "30px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 700,
  interval: 200
});
