import $ from "jquery";

$(function() {
    const $blocks = $(".bl-fadeslider");
    if($blocks.length == 0) {
        return;
    }

    $blocks.each(function() {
        const $bl = $(this);
        const $slides = $bl.find(".slide");
        let $curSlide = $slides.first();
        fadeIn($curSlide);
        setTimeout(nextSlide, 4000);

        let zindex = 1;

        function fadeIn($slide) {
            $slide.removeClass("active");
            $slide.get(0).offsetHeight; //trigger reflow
            $slide.show();
            $slide.addClass("active");
            $slide.css("z-index", zindex);
            zindex++;
        }        

        function nextSlide() {
            let $nextSlide = $curSlide.next();
            if($nextSlide.length == 0) {
                $nextSlide = $bl.find(".slide:first");
            }
            $nextSlide.removeClass("initialslide");
            fadeIn($nextSlide);
            $curSlide = $nextSlide;

            setTimeout(nextSlide, 6000);
        }
    });
});
