import $ from "jquery";

$(function() {
    const $contactItem = $("a[href='#kontakt']").on("click", function(ev) {
        ev.preventDefault();
        
        const scrollTarget = $("footer .col2").get(0);
        scrollTarget.scrollIntoView({
            behavior: "smooth"
        });

        $('#menu-offcanvas').foundation('close');
        //$("#hamburger button").trigger("click");
    });
});